import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
    FirstScreen,
    ServicesAdvantages,
    HowItWorksAuto,
    Components,
    StagesOfWork,
    InteractiveAuto,
    Garants
} from '../components/ServicesAndPrices'
import { FormOrder, Breadcrumbs } from '../components/Global'
// content
import YAMLGlobal from "../content/ru/global.yaml"
import YAMLService from "../content/ru/service.yaml"

const ServicesReservePage = ({location}) => {
    const serviceLocal = {
        langBase: "",
        YAMLGlobal,
        YAMLContent: YAMLService
    }
    
    const data = useStaticQuery(graphql`
        query {
            seo: directusPricesAutoSeo {
                title
                description
            }
            firstscreen: allDirectusPricesFirstscreen {
                edges {
                    node {
                        auto_description
                        auto_title
                        auto_image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1600) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            advantages: allDirectusPricesAutoAdvantage {
                edges {
                    node {
                        id
                        title
                        description
                    }
                }
            },
            slides: allDirectusPricesAutoComponent {
                edges {
                    node {
                        id
                        description
                        title
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        logos {
                            id
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            stages: allDirectusStepsWorkRu(sort: {fields: sort}) {
                edges {
                    node {
                        id
                        list
                        sort
                        title
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 700) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            interactive: allDirectusPricesservicesreserve {
                edges {
                    node {
                        avtonom_200_price_ses
                        avtonom_250_price_ses
                        avtonom_300_price_ses
                        avtonom_350_price_ses
                        avtonom_400_price_ses
                        avtonom_450_price_ses
                        avtonom_500_price_ses
                    }
                }
            },
            garants: allDirectusPricesGuarantee {
                edges {
                    node {
                        id
                        description
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    
    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/services-auto',
            title: YAMLGlobal.menu.services.auto
        }
    ]
    
    return(
        <Layout local={serviceLocal} location={location}>
            <SEO title={data.seo.title} description={data.seo.description} />
            <Breadcrumbs list={list}/>
            <FirstScreen content={{
                title: data.firstscreen.edges[0].node.auto_title,
                text: data.firstscreen.edges[0].node.auto_description,
                img: data.firstscreen.edges[0].node.auto_image.localFile?.childImageSharp.fluid
            }} />
            <ServicesAdvantages advantages={data.advantages.edges}/>
            <HowItWorksAuto/>
            <Components background="gray" slides={data.slides.edges}/>
            <StagesOfWork stages={data.stages.edges}/>
            <InteractiveAuto interactive={data.interactive.edges[0].node}/>
            <Garants wave={true} garants={data.garants.edges}/>
            <FormOrder wave="white"/>
        </Layout>
    )
}

export default ServicesReservePage;